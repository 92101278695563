import Layout from '../components/Layout';

export default function NotFound() {
  return (
    <Layout contentTitle="Sivua ei löydy">
      <p>Valitettavasti etsimääsi sivua ei löytynyt.</p>
      <p>
        {` `}
        Tarkista, että olet kirjoittanut osoitteen oikein. Voit myös yrittää
        löytää etsimääsi <a href="/">etusivun</a> kautta.
      </p>
    </Layout>
  );
}
